@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials


body {
    font-family: "Space Grotesk", Times, serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "DM Sans",-apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;
}

